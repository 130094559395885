<template>
    <v-autocomplete v-model="selectedStore" :items="stores" @change="onChange" item-text="name" item-value="id" label="Loja" return-object="true" :disabled="!canSelect"
        :loading="!loaded" clearable>
        <template slot="selection" slot-scope="data"> {{ data.item.name }} - {{ data.item.city }} </template>
        <template slot="item" slot-scope="data"> {{ data.item.name }} - {{ data.item.city }} </template>
    </v-autocomplete>
</template>

<script>
export default {

    data() {
        return {
            stores: [],
            selectedStore: null,
            loaded: false,
            canSelect: false
        };
    },

    created() {
        this.$http
            .get("stores/ByUser")
            .then((result) => {
                this.stores = result;
                if (this.stores.length > 1) {
                    this.canSelect = true;
                } else if (this.stores.length == 1) {
                    this.selectedStore = this.stores[0];
                    this.onChange();
                }
                this.loaded = true;
            })
            .catch(() => {
                this.stores = [];
            });
    },

    methods: {
        onChange() {
            this.$emit("onChange", this.selectedStore);
        },
    }
};
</script>