<template>
    <v-card dark :color="selectedClient != null ? (clientValid ? 'primary' : 'error') : 'grey'">
        <v-container grid-list-md text-xs-center fluid>
            <v-layout wrap>
                <v-flex xs1 sm1 md1>
                    <span class="material-icons" v-if="selectedClient != null"> check_circle </span>
                </v-flex>

                <v-flex xs11 sm11 md8>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-list-item-title class="headline"> Cliente </v-list-item-title>

                            <v-list-item-subtitle v-if="selectedClient == null"> Selecione um cliente </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="selectedClient != null"> Nome: {{ selectedClient.name }} </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="selectedClient != null && selectedClient.birthDate != null">
                                Nascimento: {{ selectedClient.birthDate ? $moment(selectedClient.birthDate).format("DD/MM/YYYY") : "" }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="selectedClient != null && selectedClient.motherName != null">
                                Mãe: {{ selectedClient.motherName }}
                            </v-list-item-subtitle>
                        </div>
                    </div>
                </v-flex>

                <v-flex xs12 sm12 md3>
                    <SimpleSearch formclass="Clients/form" endpoint="clients" :aditionalColumns="headersClient" @onChoose="onChange">
                        <template> Pesquisa de Clientes </template>
                        <template v-slot:extra-column="item">
                            <v-chip class="ma-0" outlined color="success" v-if="item.birthDate"> {{ $moment(item.birthDate).format("DD/MM/YYYY") }} </v-chip>
                        </template>
                        <template v-slot:more-btn="item">
                            <v-chip class="ma-0" outlined color="error" v-if="!item.motherName">
                                <v-avatar left> <v-icon>error</v-icon> </v-avatar>
                                Necessário atualizar
                            </v-chip>
                        </template>
                    </SimpleSearch>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
import SimpleSearch from "@/components/core/SimpleSearch";

export default {
    components: { SimpleSearch },

    data() {
        return {
            selectedClient: null,
            headersClient: [
                { text: "Nome", value: "name" },
                { text: "CPF", value: "cpf" },
                // { text: this.$t("Mãe"), value: "motherName", align: "right", sortable: true },
                { text: this.$t("Data de Nascimento"), value: "extra-column", align: "right", sortable: true },
            ],
        };
    },

    computed: {
        clientValid() {
            return this.selectedClient && this.selectedClient.motherName != null && this.selectedClient.birthDate != null;
        },
    },

    methods: {
        onChange(item) {
            this.selectedClient = item;
            this.$emit("onChange", this.selectedClient);
        },
    }
};
</script>