<template>
    <v-alert border="bottom" colored-border icon="search" color="grey" outlined>
        <v-layout wrap>

            <v-flex xs12 v-if="!hideStore">
                <SelectStore @onChange="changeStore" />
            </v-flex>

            <v-flex xs12 v-if="!hideDate">
                <DateInterval :label="$t('Data')" @onChange="changeDate" limit="31" />
            </v-flex>

            <v-flex xs12 v-if="!hideClient">
                <SelectClient @onChange="changeClient" />
            </v-flex>

        </v-layout>
    </v-alert>
</template>

<script>
import DateInterval from "@/components/core/DateInterval";
import SelectStore from "@/components/shared/SelectStore";
import SelectClient from "@/components/shared/SelectClient";

export default {

    components: { DateInterval, SelectStore, SelectClient },


    props: {
        hideClient: {
            type: Boolean,
            default: false,
        },
        hideStore: {
            type: Boolean,
            default: false,
        },
        hideDate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dates: [],
            selectedStore: null,
            selectedClient: null,
        };
    },


    methods: {
        changeDate(event) {
            this.dates = event;
            this.$emit("changeDate", this.dates);
        },
        changeClient(item) {
            this.selectedClient = item;
            this.$emit("changeClient", this.selectedClient);
        },
        changeStore(event) {
            this.selectedStore = event;
            this.$emit("changeStore", this.selectedStore);
        },
    }
};
</script>