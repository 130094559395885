const moment = require('moment')

export default {
    showMinutes(minutes) {
        if (minutes > 1) {
            return `${minutes} minutos`;
        } else if (minutes == 1) {
            return `${minutes} minuto`;
        } else {
            return `-`;
        }
    },
    getHours(minutes) {
        return parseInt(minutes / 60);
    },
    getMinutes(minutes) {
        return (minutes % 60);
    },
    tranformTime(minutes) {
        return `${parseInt(minutes / 60)
            .toString()
            .padStart(2, "0")}h ${parseInt(minutes % 60)
                .toString()
                .padStart(2, "0")}m`;
    },
    tranformDate(_date) {
        let date = _date.split("-");
        return `${date[2].padStart(2, "0")}/${date[1].padStart(2, "0")}/${date[0]}`;
    },
    setTimeInDate(date, time) {
        const [hour, minute] = time.split(":");
        return moment.utc(date).set({ hour, minute });
    },
};